import styled from 'styled-components';

export const Container = styled.main`
  padding: 20px 20px 20px;
  min-height: 100vh;
  min-width: 100vw;

  @media (max-width: 768px) {
    padding: 40px 30px 40px;
    ${({ centred }) => centred && 'padding: 40px 30px 0px;' }
  }

  ${({ centred }) =>
    centred &&
    'position: relative; vertical-align: middle; display: table-cell; height: 100vh; width: 100vw; max-width: 100vw; overflow: hidden;'}

  ${({ centred }) => !centred && 'padding: 60px 20px 60px;'}
`;

// TODO could this move to to just the index page?
export const InnerContainer = styled.section`
  max-width: 1500px;

  @media (min-width: 768px) {
    padding: 0 60px 0px;
  }
`;
