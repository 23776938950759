import styled from 'styled-components';

export const BLOCK_ELEMENT_MARGIN = '15px 0 20px';

export const CodeBlockContainer = styled.section`
  /**
 * prism.js Dark theme for JavaScript, CSS and HTML
 * Based on the slides of the talk “/Reg(exp){2}lained/”
 * @author Lea Verou
 */

  margin: ${BLOCK_ELEMENT_MARGIN};

  code,
  pre {
    color: white;
    background: none;
    text-shadow: 0 -0.1em 0.2em black;
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    font-size: 11px;
    line-height: 21px;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }

  pre,
  :not(pre) > code[class*="language-"] {
    background: hsl(30, 20%, 25%);
  }

  div.code-toolbar > .toolbar > .toolbar-item {
    margin: 6px !important;
  }

  div.code-toolbar > .toolbar > .toolbar-item > a,
  div.code-toolbar > .toolbar > .toolbar-item > button,
  div.code-toolbar > .toolbar > .toolbar-item > span {
    padding: 5px 8px !important;
  }

  /* Code blocks */
  pre {
    padding: 1em;
    margin: 0 0 0.5em;
    overflow: auto;

    background: rgba(43, 43, 43, 0.27);

    border: 1px solid #202020;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  /* Inline code */
  :not(pre) > code[class*="language-"] {
    padding: 0.15em 0.2em 0.05em;
    border-radius: 0.3em;
    border: 0.13em solid hsl(30, 20%, 40%);
    box-shadow: 1px 1px 0.3em -0.1em black inset;
    white-space: normal;
  }
`;

export const Title = styled.a`
  display: block;
  padding: 13px;

  font-weight: bold;
  font-size: 11px;

  background: rgba(106, 106, 106, 0.2);

  border: 1px solid #202020;
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  span {
    display: block;
    font-size: 11px;
  }

  svg {
    transition: all 0.3s;
    opacity: 0;
    margin-right: -5px;
    float: right;
    height: 12px;
    fill: #fff;
  }

  &:hover {
    svg {
      opacity: 1;
      margin-right: 0px;
    }
  }
`;
