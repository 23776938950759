import React from 'react';
import styled from 'styled-components';
import { ButtonWrapper } from '../back-button';
import { MOBILE_BREAKPOINT, ANIMATION_DURATION } from '../../styles/core/global';
import { FadeIn, FadeInKeyframes } from '../../styles/core/animation';

const Container = styled.section`
    border-top: 1px solid rgb(255 255 255 / 10%);
    border-bottom: 1px solid rgb(255 255 255 / 10%);

    padding: 10px 0;
    margin: 20px 0;

    opacity: 0;
    animation: ${FadeInKeyframes} ${ANIMATION_DURATION} 0.3s forwards;

    @media (min-width: ${MOBILE_BREAKPOINT}) {
        display: grid;
        grid-template-columns: 50% 50%;

        padding: 20px 10px;
        margin: 40px 0 30px;
    }
`;

const ButtonContainer = styled.div`
    width: 100%;
    margin: 10px 0;

    span {
        display: block;
    }

    &.Previous {
        text-align: right;
    }
`;

export const ArticleNav = ({ next, previous }) => (
  <Container>
    <div>{next && renderButton({ ...next, label: 'Next', alternate: true })}</div>
    <div>{previous && renderButton({ ...previous, label: 'Previous' })}</div>
  </Container>
);

const renderButton = ({ label, url, title, alternate }) => (
  <ButtonContainer className={label}><FadeIn>{label}</FadeIn><ButtonWrapper className={alternate ? 'alternate' : '' } href={url}>{title}</ButtonWrapper></ButtonContainer>
);
