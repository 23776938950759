import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import { FadeInKeyframes } from '../styles/core/animation';

const BackgroundImageContainer = styled.div`
  position: fixed;
  opacity: .5;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;

  .backgroundImg {
    width: 100%;
    height: 100%;
    object-fit: cover;

    animation: ${FadeInKeyframes} 2s forwards;
    opacity: 0;
  }  
`;

const PageBackground = () => {
  const { allContentfulGeneral: { nodes } } = useStaticQuery(graphql`
    {
      allContentfulGeneral {
        nodes {
          backgrounds {
            file {
              url
            }
          }
        }
      }
    }
  `);

  const url = nodes[0].backgrounds[randomIntFromInterval(0, nodes[0].backgrounds.length - 1)].file.url;

  return <BackgroundImageContainer><img className="backgroundImg" src={url} alt="" /></BackgroundImageContainer>;
};

/**
 * Generate a random number between to boundaries.
 * @param {int} min Bottom Boundry.
 * @param {int} max Top Boundry.
 * @return {int} A random number.
 */
function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default PageBackground;


