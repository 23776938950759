import React from 'react';
import styled from 'styled-components';
import { ANIMATION_DURATION, FadeInKeyframes } from '../styles/core/animation';
import Logo from '../images/signatureH.png';

export const SiteLogo = styled.img`
  width: 70px;

  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 20px;

  margin-bottom: -26px;
  margin-left: -20px;
`;

export const Header = styled.header`
  margin-bottom: 0px;
  max-width: 950px;
  animation: ${FadeInKeyframes} ${ANIMATION_DURATION} 0s forwards;
  opacity: 0;

  ${({ border }) => border && `
    border-bottom: 1px solid rgba(204, 204, 204, 0.2);
    padding-bottom: 20px;
  `}
`;

export const Heading = styled.h1`
  margin-bottom: 0;
  font-size: 28px;
  line-height: 44px;
`;

export const Introduction = styled.p`
  animation: ${FadeInKeyframes} ${ANIMATION_DURATION} 0.2s forwards;
  opacity: 0;
  max-width: 700px;
`;

export const PageHeader = ({ useLogo = false, heading, introduction, border = false }) => (
  <Header border={border}>
    {useLogo ? <SiteLogo src={Logo} alt="Harry Northover" /> : <Heading>{heading}</Heading>}
    {introduction && <Introduction>{introduction}</Introduction>}
  </Header>
);
