import styled from 'styled-components';
import React from 'react';

import { ANIMATION_DURATION, FadeInKeyframes, FadeInSlideKeyframes } from '../styles/core/animation';

export const LinkGridItem = styled.div`
  display: grid;

  &:first-of-type {
    padding-top: 0;
  }

  grid-template-columns: min-content auto;
  grid-template-rows: repeat(2, min-content);
  grid-column-gap: ${(props) => props.iconPresent ? `20px` : 0};
  grid-row-gap: 0px;

  padding-top: 30px;
  align-items: center;

  &:nth-of-type(1) {
    img {
      animation-delay: 1.3s;
    }
  }

  &:nth-of-type(2) {
    img {
      animation-delay: 1.4s;
    }
  }

  &:nth-of-type(3) {
    img {
      animation-delay: 1.5s;
    }
  }

  &:nth-of-type(4) {
    img {
      animation-delay: 1.6s;
    }
  }

  &:nth-of-type(5) {
    img {
      animation-delay: 1.7s;
    }
  }

  &:nth-of-type(6) {
    img {
      animation-delay: 1.8s;
    }
  }

  &:nth-of-type(7) {
    img {
      animation-delay: 1.9s;
    }
  }

  &:nth-of-type(8) {
    img {
      animation-delay: 2s;
    }
  }
`;

export const Icon = styled.img`
  grid-area: 1 / 1 / 2 / 2;
  padding: 0;
  width: 20px;

  opacity: 0;
  margin-left: -5px;
  margin-right: 5px;

  animation: ${FadeInSlideKeyframes} ${ANIMATION_DURATION} 1s forwards;
`;

export const Title = styled.h3`
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 12px;
  grid-area: 1 / 2 / 2 / 3;
  margin: 0;
  line-height: 29px;
  opacity: 0;

  animation: ${FadeInKeyframes} ${ANIMATION_DURATION} 1s forwards;
`;

export const Description = styled.p`
  grid-area: 2 / 2 / 3 / 3;
  font-size: 12px;
  margin: 0;
  opacity: 0;
  color: #bbb;
  max-width: 100%;

  animation: ${FadeInKeyframes} ${ANIMATION_DURATION} 1.2s forwards;
`;

const LinkItem = ({
  icon,
  name,
  description,
  url,
  internalLink,
  iconPresent = icon && icon.file && icon.file.url,
}) => {
  const dest = description && description.description;
  const isInternal = internalLink && internalLink.url;
  const link = isInternal ? internalLink.url : url;
  const target = isInternal ? '_self' : '_blank';

  return (
    <LinkGridItem key={name} iconPresent={iconPresent}>
      {iconPresent && <Icon src={icon.file.url} alt=""></Icon>}
      <Title>
        <a href={link} target={target} rel="noreferrer">
          {name}
        </a>
      </Title>
      {dest && <Description>{dest}</Description>}
    </LinkGridItem>
  );
};

export default LinkItem;
