import React, { useEffect } from 'react';
import { HintContainer, HintContent, Title } from './hint.styles';
import { MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import * as Prism from 'prismjs';
import 'prismjs/components/prism-php';
import InfoIcon from '../icons/info-icon';

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b>{text}</b>,
    [MARKS.CODE]: (code) => <code>{code}</code>,
  },
};

export const Hint = ({ title, slug, content, type }) => {
  useEffect(() => {
    Prism.highlightAll();
  });

  return (
    <HintContainer>
      <Title id={slug} href={`#${slug}`} className={type}><InfoIcon /><span>{title}</span></Title>
      <HintContent className={`${type} language-javascript`}>{renderRichText(content, options)}</HintContent>
    </HintContainer>
  );
};
