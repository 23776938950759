import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ANIMATION_DURATION, FadeInKeyframes } from '../styles/core/animation';

export const Title = styled.div`
  display: block;
  padding: 13px;

  font-weight: bold;
  font-size: 11px;

  background: rgba(106, 106, 106, 0.2);

  span {
    display: block;
    font-size: 11px;
  }
`;

const Container = styled.div`
    margin: 15px auto;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgba(106, 106, 106, 0.2);

    opacity: 0;
    animation: ${FadeInKeyframes} ${ANIMATION_DURATION} 0.6s forwards;

    .gatsby-image-wrapper img {
        padding: 10px;

        width: 100% !important;
        height: auto !important;
    }

`;

const renderTitle = ({ title, description }) => (
  <Title>
    {title}
    {description && <span>{description}</span>}
  </Title>
);

export const Image = ({ title = '', description = '', imageData }) => {
  return (
    <Container>
      {title && renderTitle({ title, description })}
      <GatsbyImage
        image={imageData}
        alt={title}
        className="article-image-container"
        imgClassName="article-image"
      />
    </Container>
  );
};
