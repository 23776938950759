import React from 'react';

const LinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="98.94 52.5 502.13 455">
    <g>
      <path d="m361.67 344.17h-58.332c-9.5664 0-17.5-7.9336-17.5-17.5 0-9.5664 7.9336-17.5 17.5-17.5h58.332c61.133 0 110.83-49.703 110.83-110.84 0-61.133-49.699-110.83-110.83-110.83h-116.67c-61.133 0-110.83 49.699-110.83 110.83 0 25.668 9.1016 50.633 25.434 70.469 6.0664 7.4648 5.1328 18.434-2.332 24.734-7.4688 6.0664-18.434 5.1328-24.734-2.3359-21.699-26.133-33.602-59.031-33.602-92.867 0-80.5 65.336-145.83 145.84-145.83h116.66c80.5 0 145.83 65.332 145.83 145.83s-65.098 145.84-145.6 145.84z"></path>  <path d="m455 507.5h-116.67c-80.5 0-145.83-65.332-145.83-145.83s65.332-145.84 145.83-145.84h58.336c9.5664 0 17.5 7.9336 17.5 17.5 0 9.5664-7.9336 17.5-17.5 17.5h-58.336c-61.133 0-110.83 49.703-110.83 110.84 0 61.133 49.699 110.83 110.83 110.83h116.67c61.133 0 110.83-49.699 110.83-110.83 0-25.668-9.0977-50.633-25.434-70.469-6.0664-7.4648-5.1328-18.434 2.3359-24.734 7.4648-6.2969 18.434-5.1328 24.73 2.3359 21.703 26.133 33.602 59.031 33.602 92.867-0.23438 80.5-65.566 145.83-146.07 145.83z"></path>
    </g>
  </svg>
);

export default LinkIcon;
