import React from 'react';

const InfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="264.32 84 170.87 392" width={'7px'} fill="#FFF">
    <g>
      <path d="m346.64 84c-30.238 0-54.32 24.641-54.32 54.32 0 30.238 24.641 54.32 54.32 54.32 30.238 0 54.32-24.641 54.32-54.32 0-29.68-24.641-54.32-54.32-54.32z"></path>        <path d="m430.08 404.32h-23.52c-3.3594 0-5.6016-2.8008-5.6016-5.6016l0.003907-165.76c0-3.3594-2.8008-5.6016-5.6016-5.6016h-125.44c-3.3594 0-5.6016 2.8008-5.6016 5.6016v41.438c0 2.8008 2.2383 5.6016 5.6016 5.6016l25.199 0.55859c2.8008 0 5.6016 2.8008 5.6016 5.6016v112c0 3.3594-2.8008 5.6016-5.6016 5.6016h-23.52c-3.3594 0-5.6016 2.8008-5.6016 5.6016v61.035c0 3.3594 2.8008 5.6016 5.6016 5.6016h157.92c3.3594 0 5.6016-2.8008 5.6016-5.6016l-0.003906-60.477c0.55859-3.3633-2.2422-5.6016-5.043-5.6016z"></path>
    </g>
  </svg >
);

export default InfoIcon;
