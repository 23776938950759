import styled from 'styled-components';
import { FadeInKeyframes, ANIMATION_DURATION } from '../core/animation';
import { MOBILE_BREAKPOINT } from '../core/global';

export const Header = styled.header`
  max-width: 650px;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    width: calc(100vw - 57vw);
  }
`;

export const Content = styled.div`
  max-width: 650px;

  @media (min-width: 768px) {
    width: calc(100vw - 55vw);
  }
`;

export const Meta = styled.div`
  margin-top: 0px;
  background: rgb(204 204 204 / 5%);
  margin: 20px -40px 0;
  padding: 20px;

  @media (min-width: 768px) {
    width: 40vw;
    max-width: 500px;

    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    vertical-align: middle;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 30px;
    margin: 0;
  }
`;

export const Articles = styled.div`
  padding: 20px 0 20px;
  max-width: 750px;

  > div {
    width: 100%;
    margin-bottom: 40px;

    span {
      max-width: 400px;
    }
  }

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    padding: 20px 0 0;
  }
`;

export const ArticleGrid = styled.div`
  display:grid;
  grid-gap: 40px;
  max-width: 950px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    
  }

  padding-top: 0px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-bottom: 10px;
  width: 100%;

  animation: ${FadeInKeyframes} ${ANIMATION_DURATION} 0.4s forwards;
  opacity: 0;

  > div {
    /* margin-top: 40px !important; */

    span {
      max-width: 400px;
    }
  }

  > div:first-of-type {
    margin-top: 0 !important;
  }
`;

export const Links = styled.section`
  padding: 20px;
  max-width: 500px;
`;
