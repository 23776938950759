import { createGlobalStyle } from 'styled-components';

const hoverColors = ['#5da5c8', '#3d4dff', '#ff213f', '#d2f230'];
const hoverColor = hoverColors[(hoverColors.length * Math.random()) | 0];

export const Typography = createGlobalStyle`
  /* 
   * Heading Styles.
   */

  h1, h2 {
    font-family: "Merriweather", serif;
    text-transform: capitalize;
    margin-bottom: 15px;
  }

  h3, h4, h5, h6 {
    font-family: "Lato", sans-serif;
    margin-bottom: 15px;
  }

  h1 {
    font-size: 30px;
    letter-spacing: normal;
    font-weight: 600;
  }

  h2 {
    font-size: 20px;
    font-weight: 500 !important;
    line-height: 34px;
  }

  h3 {
    font-size: 16px;
    line-height: 30px;
    font-weight: 700;
    margin-top: 30px;
  }

  h4 {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    line-height: 28px;
    font-size: 11px;
    margin-top: 25px;
  }

  h3 + p,
  h2 + p,
  h4 + p {
    margin-top: -15px;
  }


  h2 + h3,
  h3 + h4 {
    margin-top: 10px;
  }

  /* 
   * Text Content 
   */

  p, span, a, div {
    font-family: "Lato", sans-serif;
    font-style: normal;
  }

  p, span {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    color: #d6d6d6;
  }

  b {
    color: #FFF;
  }

  a {
    text-decoration: none;
    color: #FFF;
    font-weight: 600;

    display: inline-block;
    position: relative;

    :after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: ${hoverColor}; /*#5da5c8;*/
      transform-origin: bottom right;
      transition: transform 0.40s;
      transition-timing-function: cubic-bezier(1.000, 0.000, 0.000, 1.000);
    }

    :hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    &.alternate {
      :hover:after {
        transform-origin: bottom right;
      }
    }
  }

  ol, ul {
    padding-top: 0;
  }
`;
