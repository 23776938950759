import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
      query MyQuery {
        contentfulSeo {
          siteTitle
          siteDescription
          keywords
        }
      }
  `);

  return data.contentfulSeo;
};
