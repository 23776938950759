import styled from 'styled-components';

import {
  ANIMATION_DURATION,
  FadeInKeyframes,
  FadeUpKeyframes,
} from '../../styles/core/animation';

export const TitleContainer = styled.header`
  opacity: 0;
  animation: ${FadeInKeyframes} ${ANIMATION_DURATION} 0.3s forwards;
`;

export const ArticleTitle = styled.h1`
  margin-bottom: 10px;
`;

export const AuthorName = styled.span`
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 20px;
  display: block;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  font-size: 10px;
`;

export const Content = styled.section`
  margin: 0 0 30px;
  max-width: 900px;
`;

export const ArticleContent = styled.article`
  margin: 40px 0 20px;

  opacity: 0;
  animation: ${FadeUpKeyframes} ${ANIMATION_DURATION} 0.6s forwards;

  > p:first-of-type {
    font-family: "Merriweather", serif;
    font-size: 20px;
    line-height: 32px;
    margin-top: 30px;
    color: #fff;

    a {
      font-family: "Merriweather", serif;
    }
  }

  blockquote {
    display: block;

    font-family: "Merriweather", serif;
    font-style: italic;
    font-weight: 500;

    border-left: 3px solid white;
    padding: 1px 20px;
    margin: 0 20px;

    a, p {
      font-family: "Merriweather", serif;
    }
  }

  code {
    background: #e0e0e01c;
    padding: 2px 4px;
    margin: 0 6px;
    border-radius: 2px;
  }

  a {
    code {
      border: none;
      padding: 0;
      display: inline;
      background: none;
    }
  }

  h2 {
    margin-top: 40px;
    margin-bottom: 20px;

    a {
      padding-bottom: 5px;
    }
  }

  li {
    p {
      margin: 0px 0 10px;
    }

    ::marker {
      font-family: lato, sans-serif;
      font-size: 13px;
      font-weight: 500;
      margin: 0px;
      color: rgb(214, 214, 214);
    }
  }
`;
