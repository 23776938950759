import React from 'react';
import { Helmet } from 'react-helmet';
import { BackButton } from '../components/back-button';
import Page from '../templates/page/page.tmpl';
import { PageHeader } from '../components/page-header';

const NotFound = () => (
  <Page>
    <Helmet>
      <title>Not Found | Harry Northover</title>
    </Helmet>
    <PageHeader
      heading="404"
      introduction="Sorry, this page cannot be found."
    />
    <BackButton url={'/'}>Go Home</BackButton>
  </Page>
);

export default NotFound;
