import React from 'react';
import { graphql } from 'gatsby';
import * as queryString from 'query-string';

import Page from '../../templates/page/page.tmpl';
import ArticlePreview from '../../components/article-preview';
import { ArticleGrid } from '../../styles/pages';
import { BackButton } from '../../components/back-button';
import { PageHeader } from '../../components/page-header';
import { SEO } from '../../components/seo';
import { SEARCH_QUERY_TAGS } from '../../utils';

const renderArticles = (articles, location) => {
  // eslint-disable-next-line no-unused-vars
  const params = queryString.parse(location.search);
  const tags = params[SEARCH_QUERY_TAGS.Tags] ?
    params[SEARCH_QUERY_TAGS.Tags].split(',') :
    [];

  const filteredArticles = articles.filter((article, i) => {
    const articleTags = article.tags.map((tag) => tag.toLowerCase());
    let result = false;

    if (tags.length > 0) {
      tags.forEach((tag) => {
        if (articleTags.includes(tag.toLowerCase())) {
          // article has tag
          result = true;
        }
      });
    } else {
      return true;
    }

    return result;
  });

  if (filteredArticles.length > 0) {
    return filteredArticles.map((article, i) => (
      <ArticlePreview {...article} delay={i} key={article.title} />
    ));
  } else {
    return <h3 style={{ marginTop: '-30px' }}>No articles found...</h3>;
  }
};

const ArticlesIndex = ({
  data: {
    allContentfulArticle: { nodes },
  },
  location,
}) => {
  return (
    <Page>
      <SEO
        title={`Articles | Harry Northover`}
        description={`A collection articles, thoughts and anything else I feel worth writing
        about.`}
      />
      <PageHeader border heading="Articles" />
      <ArticleGrid>{renderArticles(nodes, location)}</ArticleGrid>
      <BackButton url="/" />
    </Page>
  );
};

export const query = graphql`
  {
    allContentfulArticle(sort: { fields: createdAt, order: DESC }) {
      nodes {
        title
        tags
        url
        introduction {
          introduction
        }
      }
    }
  }
`;

export default ArticlesIndex;
