import React from 'react';
import { graphql } from 'gatsby';

import {
  Header,
  Links,
  Meta,
  Articles,
  Content,
} from '../styles/pages';
import Page from '../templates/page/page.tmpl';
import ArticlePreview from '../components/article-preview';
import { ButtonWrapper } from '../components/back-button';
import LinkItem from '../components/link-item';
import { PageHeader } from '../components/page-header';

const Home = ({ data: { allContentfulGeneral, allContentfulArticle } }) => {
  const {
    introduction: { introduction },
    links,
  } = allContentfulGeneral.nodes[0];

  const articles = allContentfulArticle.nodes;

  return (
    <Page centred={true}>
      <Header>
        <PageHeader
          useLogo
          heading="Harry Northover"
          introduction={introduction}
        />
      </Header>
      <Content>
        <Articles>
          {articles.map((article, i) => (
            <ArticlePreview
              key={article.title.replace(/[\s:]+/g, '-')}
              {...article}
              delay={i}
            />
          ))}
          <ButtonWrapper href="/articles">View All &#x27A1;</ButtonWrapper>
        </Articles>
      </Content>
      <Meta>
        <Links>
          {links.map((link) => (
            <LinkItem key={link.name} {...link} />
          ))}
        </Links>
      </Meta>
    </Page>
  );
};

export const query = graphql`
  {
    allContentfulGeneral {
      nodes {
        title
        contactEmail
        introduction {
          introduction
        }
        links {
          icon {
            file {
              url
            }
          }
          description {
            description
          }
          name
          url
          internalLink {
            url
          }
        }
      }
    }
    allContentfulArticle(limit: 2, sort: { fields: createdAt, order: DESC }) {
      nodes {
        title
        tags
        url
        introduction {
          introduction
        }
      }
    }
  }
`;

export default Home;
