import React from 'react';

import Page from '../page/page.tmpl';
import LinkItem from '../../components/link-item';
import styled from 'styled-components';
import { ANIMATION_DURATION, FadeInKeyframes } from '../../styles/core/animation';
import { BackButton } from '../../components/back-button';
import { PageHeader } from '../../components/page-header';
import { SEO } from '../../components/seo';

const Content = styled.div`
  margin-top: 20px;
  min-height: 600px;
  width: 100%;
  max-width: 950px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
    margin-top: -10px;
  }
`;

const Section = styled.section`
  max-width: 450px;
  opacity: 0;
  margin-bottom: 30px;
  animation: ${FadeInKeyframes} ${ANIMATION_DURATION} 0.5s forwards;
  animation-delay: ${(props) => props.delay / 5 + 0.5 + 's'};

 @media (min-width: 768px) {
  margin: 30px 0;
 } 
`;

const Title = styled.h2`
    margin-bottom: 0;
`;

const Description = styled.p`
  margin-top: 5px;
  margin-bottom: 25px;
  max-width: 400px;
  display: block;
`;

const renderSection = ({ title, description, links, index }) => (
  <Section key={title} delay={index}>
    <Title>{title}</Title>
    <Description>{description}</Description>
    {links.map((link) => <LinkItem key={link.name} {...link} />)}
  </Section>
);

const Resources = ({
  pageContext: {
    title,
    description: { description },
    sections,
  },
}) => (
  <Page>
    <SEO title={`${title} | Harry Northover`} description={description} keywords={sections.map((section) => section.title)} />
    <PageHeader heading={title} introduction={description} border />
    <Content>
      {sections.map(({ title, description: { description }, links }, i) => renderSection({ title, description, links, index: i }))}
    </Content>
    <BackButton url={`/`} />
  </Page>
);

export default Resources;
