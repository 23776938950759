import React from 'react';
import styled from 'styled-components';
import { SEARCH_QUERY_TAGS } from '../utils';

const TagContainer = styled.div`
  margin: 0 0 20px;
`;

const Tag = styled.a`
  border-radius: 3px;
  margin-bottom: 0;
  padding: 12px;
  text-transform: uppercase;
  font-size: 9px;
  color: #fff;
  background-color: rgba(108, 108, 141, 0.2);
  margin-right: 7px;
  margin-top:10px;
  letter-spacing: 1.4px;
  font-weight: 700;
  display:inline-block;
`;

const renderTags = (tags) => tags.map((tag) => <Tag key={tag} href={`/articles?${SEARCH_QUERY_TAGS.Tags}=${tag}`}>{tag}</Tag>);

const tags = ({ tags }) => (
  <TagContainer>{renderTags(tags)}</TagContainer>
);

export default tags;
