import React, { useEffect } from 'react';
import { CodeBlockContainer, Title } from './code-block.styles';
import * as Prism from 'prismjs';
import 'prismjs/components/prism-php';
import LinkIcon from '../icons/link-icon';

export const CodeBlock = ({ code, language, description, title }) => {
  const id = title.replace(/[\s:,]+/g, '-').toLowerCase();

  useEffect(() => {
    Prism.highlightAll();
  });

  return (
    <CodeBlockContainer>
      <Title id={id} href={`#${id}`}>
        {title}
        <LinkIcon className="icon" />
        {description && <span>{description}</span>}
      </Title>
      <pre className={`language-${language} line-numbers`}>
        <code>{code}</code>
      </pre>
    </CodeBlockContainer>
  );
};
