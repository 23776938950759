import styled from 'styled-components';
import { BLOCK_ELEMENT_MARGIN } from '../code-block/code-block.styles';

export const HintContent = styled.div`
  padding: 1em;
  margin: 0 0 0.5em;
  overflow: auto;

  &.Information {
    background: rgb(87 195 255 / 9%);
  }

  &.Warning {
    background: rgb(255 87 87 / 9%);
  }

  &.Lab {
    background: rgba(255, 0, 214, 0.075)
  }

  border: 1px solid #202020;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  p {
    margin: 0px 0 5px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const HintContainer = styled.section`
  /**
 * prism.js Dark theme for JavaScript, CSS and HTML
 * Based on the slides of the talk “/Reg(exp){2}lained/”
 * @author Lea Verou
 */

  margin: ${BLOCK_ELEMENT_MARGIN};

  code,
  pre {
    color: white;
    background: none;
    text-shadow: 0 -0.1em 0.2em black;
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    font-size: 11px;
    line-height: 21px;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }

  pre,
  :not(pre) > code[class*="language-"] {
    background: hsl(30, 20%, 25%);
  }

  /* Code blocks */
  pre {
    padding: 1em;
    margin: 0 0 0.5em;
    overflow: auto;

    border: 1px solid #202020;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  /* Inline code */
  :not(pre) > code[class*="language-"] {
    padding: 0.15em 0.2em 0.05em;
    border-radius: 0.3em;
    border: 0.13em solid hsl(30, 20%, 40%);
    box-shadow: 1px 1px 0.3em -0.1em black inset;
    white-space: normal;
  }


`;

export const Title = styled.a`
  display: block;
  padding: 11px 10px;

  &:hover {
    :after {
      background-color: #fff;
    }
  }

  font-weight: bold;
  font-size: 11px;

  &.Information {
    background: rgb(87 195 255 / 80%);
  }

  &.Warning {
    background: rgb(255 87 87 / 80%);
  }

  &.Lab {
    background: rgba(255, 0, 214, 0.75)
  }

  border: 1px solid #202020;
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  span {
    display: inline;
    font-weight: 700;
  }

  svg {
    margin-right: 12px;
    margin-bottom: -2px;
    margin-left: 7px;
  }
`;
