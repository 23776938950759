import styled, { keyframes } from 'styled-components';

export const ANIMATION_DURATION = '1.5s';
export const DEFAULT_DELAY = 0.3;

/*
 * Keyframes
 */

export const FadeInKeyframes = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const FadeInSlideKeyframes = keyframes`
  0% {
    opacity: 0;
    margin-left:-5px;
    margin-right:5px;
  }

  100% {
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const FadeInSlideRightKeyframes = keyframes`
  0% {
    opacity: 0;
    margin-right:-5px;
    margin-left:5px;
  }

  100% {
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const FadeUpKeyframes = keyframes`
    0% {
      opacity: 0;
      transform: translateY(5px);
    }

    100% {
      opacity: 1;
      transform: none;
    }    
`;

/*
 * Wrappers
 */
export const FadeIn = styled.span`
  opacity: 0;
  animation: ${FadeInKeyframes} ${ANIMATION_DURATION} ${({ delay }) => delay || DEFAULT_DELAY}s forwards; 
`;
