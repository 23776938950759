import React, { useEffect } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import * as Prism from 'prismjs';

import Page from '../../templates/page/page.tmpl';
import Tags from '../../components/tags';
import {
  TitleContainer,
  ArticleTitle,
  Content,
  ArticleContent,
} from './article.tmpl.styles';
import { BackButton } from '../../components/back-button';
import { ArticleNav } from '../../components/article/article-nav';
import { Comments } from '../../components/article/comments';
import { ArticleRenderConfig } from './article.config';
import { SEO } from '../../components/seo';

const Article = ({
  pageContext: {
    title,
    tags,
    url,
    content,
    enableComments,
    next,
    previous,
    introduction: { introduction },
  },
}) => {
  useEffect(() => {
    Prism.highlightAll();
  });

  return (
    <Page>
      <SEO title={`${title} | Harry Northover`} description={introduction} keywords={tags} />
      <Content>
        <TitleContainer>
          <ArticleTitle>{title}</ArticleTitle>
          <Tags tags={tags} />
        </TitleContainer>
        <ArticleContent>{renderRichText(content, ArticleRenderConfig)}</ArticleContent>
        {(next || previous) && <ArticleNav next={next} previous={previous} />}
        {enableComments && <Comments url={url} title={title} />}
      </Content>
      <BackButton url="/articles" />
    </Page>
  );
};

export default Article;
