import React from 'react';
import styled from 'styled-components';
import { DiscussionEmbed } from 'disqus-react';
import { MOBILE_BREAKPOINT } from '../../styles/core/global';
import { DEFAULT_DELAY, FadeInKeyframes, ANIMATION_DURATION } from '../../styles/core/animation';

const Container = styled.section`
  margin: 20px 0;

  opacity: 0;
  animation: ${FadeInKeyframes} ${ANIMATION_DURATION} ${({ delay }) => delay || DEFAULT_DELAY}s forwards;

  @media(min-width: ${MOBILE_BREAKPOINT}) {
    margin: 40px 0;
  }
`;

export const Comments = ({ url, title }) => {
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: url, title },
  };

  return (
    <Container delay={1}>
      <div>
        <DiscussionEmbed {...disqusConfig} />
      </div>
    </Container>
  );
};
