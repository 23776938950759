import { createGlobalStyle } from 'styled-components';

export const ACTIVE_LINK_COLOR = '#5da5c8';
export const MOBILE_BREAKPOINT = '768px';

export const MainStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    color: #FFF;
    background-color: #000;

    .textarea-outer-wrapper .textarea-wrapper {
      background: none !important;
      border: 1px #292929 solid !important;
    }
  }
`;
