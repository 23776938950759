import React from 'react';
import styled from 'styled-components';
import {
  FadeInKeyframes,
  ANIMATION_DURATION,
} from '../styles/core/animation';

export const ButtonWrapper = styled.a`
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.4px;
  font-size: 12px;
  animation: ${FadeInKeyframes} ${ANIMATION_DURATION} ${(props) => props.delay || 1}s forwards;
  opacity: 0;
  padding-bottom: 10px;
`;

export const BackButton = ({ url }) => <ButtonWrapper href={url}>&#x21A9; Back</ButtonWrapper>;
