import React from 'react';
import { Fragment } from 'react';
import {
  InnerContainer,
  Container,
} from './page.tmpl.styles';
import { MainStyles } from '../../styles/core/global';
import PageBackground from '../../components/background';
import { CodeStyles } from '../../styles/core/code';
import { Typography } from '../../styles/core/typeography';
import { SEO } from '../../components/seo';

const Page = ({ centred = false, children }) => {
  return (
    <Fragment>
      <SEO />
      <MainStyles />
      <CodeStyles />
      <Typography />
      <PageBackground />
      <Container centred={centred}>
        <InnerContainer>
          {children}
        </InnerContainer>
      </Container>
    </Fragment>
  );
};

export default Page;
