import React from 'react';
import styled from 'styled-components';
import Tags from './tags';
import { ButtonWrapper } from './back-button';

import StarSVG from '../../static/svg/star.svg';
import WindowsSVG from '../../static/svg/windows.svg';
import LinuxSVG from '../../static/svg/linux.svg';

import HTBPNG from '../../static/htb.png';
import TryHackMePNG from '../../static/thm.png';
import OffSecSVG from '../../static/svg/offsec.svg';

const Container = styled.div`
    display: block;
    transition: background-color 200ms ease-in-out;
    border:1px solid #202020 !important;
    border-radius: 5px;
    overflow: hidden;

    /* &:hover {
        background: #67676717;

        &:after {
          display: none;
          content: none;
        }
    } */
`;

const Table = styled.div`
    display: grid;

    /* grid-template-columns: repeat(auto-fit, minmax(100px,1fr));
    grid-template-columns: min-content auto; */
    grid-template-columns: 1fr 60px 60px 110px 110px auto;

    overflow-x: scroll;
    overflow-y: hidden;
`;

const TableItem = styled.div`
    white-space: nowrap;

    &:last-of-type {
        > span {
          border-right: 0;
        }
      }

    &:last-of-type, &:nth-of-type(4) {
        /* grid-column: 1/7;
        padding: 0 10px;
        margin-bottom: -10px; */

        > span:last-child {
          padding: 8px 8px 9px;
        }

        div {
          margin: 0;
          margin-left: 5px;

          span {
            margin-top: 0;
            padding: 1px 6px;

            font-size: 9px;
          }
        }

        
    }
`;

const Label = styled.span`
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.1px;

    font-size: 11px;
    display: block;
    /* background: #b51738cc; */
    background: rgba(106,106,106,0.2);
    padding: 10px 15px;

    border-right: 1px solid #8282821c;
`;

const Value = styled.span`
    display: flex;

    background: rgba(43,43,43,0.27);
    border-right: 1px solid #202020;
    padding: 10px 15px;

    a {
      padding-bottom: 0;
    }
`;

const renderStar = (selected = false) => <StarSVG width="16px" height="24px" fill={selected ? 'gold' : 'grey'} />;
const renderStars = (amount = 0) => {
  const stars = [];

  for (let i = 0; i < 5; ++i) {
    stars.push(renderStar(i < amount));
  }

  return stars;
};

const renderOSLogo = (platform) => {
  switch (platform.toLowerCase()) {
    case 'linux':
      return <LinuxSVG width="25px" height="24px" />;
    case 'windows':
      return <WindowsSVG width="25px" height="24px" />;
  }
};

const renderCompanyLogo = (company) => {
  switch (company.toLowerCase()) {
    case 'offsec - practise':
    case 'offsec - play':
      return <OffSecSVG width="25px" height="24px" alt={company} />;

    case 'try hack me':
      return <TryHackMePNG width="25px" height="24px" alt={company} />;

    case 'hackthebox':
      return <HTBPNG width="25px" height="24px" alt={company} />;

    default:
      return company;
  }
};

const BoxSummary = ({
  name, rating, points, platform, type, tags, difficulty, url,
}) => {
  return (
    <Container>
      <Table>
        <TableItem><Label>Box</Label><Value><ButtonWrapper href={url} target='_blank'>{name}</ButtonWrapper></Value></TableItem>
        <TableItem><Label>Type</Label><Value>{renderOSLogo(type)}</Value></TableItem>
        <TableItem><Label>Plat.</Label><Value>{renderCompanyLogo(platform)}</Value></TableItem>
        <TableItem><Label>Difficulty</Label><Value><Tags tags={[difficulty]} /></Value></TableItem>
        {/* <TableItem><Label>Points</Label><Value>{points}</Value></TableItem> */}
        <TableItem><Label>Rating</Label><Value>{renderStars(rating)}</Value></TableItem>
        <TableItem><Label>Tags</Label><Value><Tags tags={tags} /></Value></TableItem>
      </Table>
    </Container>
  );
};

export default BoxSummary;
