import React from 'react';
import styled from 'styled-components';
import { ANIMATION_DURATION, FadeInSlideKeyframes } from '../styles/core/animation';
import Tags from './tags';

const Title = styled.a`
    display: inline-block;
    padding-bottom: 6px;
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 0;
    font-family: "Merriweather", serif;
    font-weight: bold;
    line-height: 30px;
`;

const Container = styled.div`
  opacity: 0;
  margin-left: -5px;
  margin-right: 5px;
  animation: ${FadeInSlideKeyframes} ${ANIMATION_DURATION} forwards;
  animation-delay: ${(props) => props.delay / 5 + 0.4 + 's'} ;
`;

const Introduction = styled.span`
  display: block;
`;

const ArticlePreview = ({
  title,
  url,
  tags,
  introduction: { introduction },
  delay,
}) => (
  <Container delay={delay}>
    <Title href={url}>{title}</Title>
    <Tags tags={tags} />
    <Introduction>{introduction}</Introduction>
  </Container>
);

export default ArticlePreview;
