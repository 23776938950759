import React from 'react';
import { useSiteMetadata } from '../hooks/meta';
import { Helmet } from 'react-helmet';

export const SEO = ({ title, description, keywords }) => {
  const { siteTitle: defaultTitle, siteDescription: defaultDescription, keywords: defaultKeywords } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    keywords: keywords || defaultKeywords,
  };

  return (
    <>
      <Helmet>
        <base />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords.toString(',')} />
      </Helmet>
    </>
  );
};
